import Permissions from '@configs/permissions';

export default [
  {
    title: 'ERP',
    icon: 'MaximizeIcon',
    children: [
      {
        title: 'Organisation Profile',
        route: 'erp-organisation-profile',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.OrganisationProfile' }
      },
      {
        title: 'Invoices',
        route: 'erp-invoice',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.Invoices' }
      },
      {
        title: 'Credit Notes',
        route: 'erp-credit-note',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.CreditNotes' }
      },
      {
        title: 'Accruals',
        route: 'erp-accrual',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.Accruals' }
      },
      {
        title: 'Bank Statement',
        route: 'erp-bank-statement',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.BankStatement' }
      },
      {
        title: 'Charge Code',
        route: 'erp-charge-code',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.ChargeCode' }
      },
      {
        title: 'Currency Profile',
        route: 'erp-currency-profile',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.CurrencyProfile' }
      },
      {
        title: 'Warehouse Code Mapping',
        route: 'erp-warehouse-code-mapping',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.WarehouseCodeMapping' }
      },
      {
        title: 'Email Template',
        route: 'erp-email-template',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.EmailTemplate' }
      },
      {
        title: 'Users and Teams',
        route: 'erp-user',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.UsersAndTeams' }
      },
      {
        title: 'Roles',
        route: 'erp-role',
        resource: Permissions.ERP,
        additionalResource: { erpPermissionCode: 'Menu.UsersAndTeams' }
      },
      {
        title: 'Preferences',
        route: 'erp-preference',
        resource: Permissions.ERP,
      },
      {
        title: 'Advanced',
        icon: 'CodesandboxIcon',
        children: [
          {
            title: 'API Actual Receivable',
            route: 'erp-api-actual-receiveable',
            resource: Permissions.ERP,
            additionalResource: { erpPermissionCode: 'Menu.Advanced' }
          },
          {
            title: 'API Actual Payable',
            route: 'erp-api-actual-payable',
            resource: Permissions.ERP,
            additionalResource: { erpPermissionCode: 'Menu.Advanced' }
          },
          {
            title: 'Actual Payable Summary',
            route: 'erp-api-actual-payable-summary',
            resource: Permissions.ERP,
            additionalResource: { erpPermissionCode: 'Menu.Advanced' }
          },
        ]
      },
    ],
  },
]
